import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import homeLogo from "../../Assets/main/home.jpg";
import Nft from "./Nft";
import Roadmap from "./Roadmap";
import Partner from "./Partner";
import Dao from "./Dao";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaTelegram } from "react-icons/fa";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <div className="home-content">
          <Row>
            <Col md={12} className="home-header">
              <p className="home-text d-flex justify-content-center">
                <span style={{ color: "#236aff" }}>Testing</span> Base
              </p>
              <p className="home-text2 d-flex justify-content-center">
                Fuck Fuck Fuck Fuck Fuck Fuck Fuck Fuck Fuck Fuck Fuck Fuck
                Fuck.
              </p>
              <p className="home-text2 d-flex justify-content-center pb-4">
                Fuck Fuck Fuck Fuck Fuck Fuck Fuck Fuck Fuck Fuck Fuck Fuck Fuck
                Fuck Fuck Fuck Fuck Fuck Fuck
              </p>
              <div className="ca d-flex justify-content-center">
                <ul className="home-about-social-links">
                  <li className="social-icons">
                    <a
                      href="https://t.me/Itestbasechain"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour home-social-icons">
                      <FaTelegram />
                    </a>
                  </li>
                  <li className="social-icons">
                    <a
                      href="https://twitter.com"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour home-social-icons">
                      <AiOutlineTwitter />
                    </a>
                  </li>
                </ul>
                <span></span>
              </div>
            </Col>
            <Col md={12} style={{ paddingBottom: 20 }}>
              <div className="d-flex justify-content-center pt-4">
                <img src={homeLogo} alt="home pic" className="logoRotate" />
              </div>
              <p className="home-text3 d-flex justify-content-center pt-4">
                {" "}
                Fuck Buy now! Fuck Buy now! Fuck Buy now! Fuck Buy now! Fuck Buy
                now! Fuck Buy now! Fuck Buy now! Fuck Buy now! Fuck Buy now!
                Fuck!.
              </p>
            </Col>
          </Row>
        </div>
        <Nft />
        <Roadmap />
        <Partner />
        <Dao />
      </Container>
    </section>
  );
}

export default Home;
