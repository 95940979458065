import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Dao() {
  return (
    <Container fluid className="nft-section" id="dao">
      <div className="nft-content">
        <div className="nft-text">
          <div className="nft-hr"></div>
          <span>DAO</span>
        </div>
        <span className="nft-text2">COMMING SOON!</span>
      </div>
    </Container>
  );
}
export default Dao;
