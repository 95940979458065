import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../../Assets/main/logo.png";
import partner1 from "../../Assets/partners/base.svg";
import partner2 from "../../Assets/partners/2.png";
import partner3 from "../../Assets/partners/3.png";
import partner4 from "../../Assets/partners/4.png";
import partner5 from "../../Assets/partners/5.png";
import partner6 from "../../Assets/partners/6.png";
import partner7 from "../../Assets/partners/7.png";

function Resume() {
  return (
    <Container fluid className="resume-section" id="partner">
      <div className="nft-content">
        <div className="nft-text">
          <div className="nft-hr"></div>
          <span>Partner</span>
        </div>
        <Row>
          <Col md={12}>
            <div className="find-us-on">
              <div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://basescan.org/">
                  <img src={partner1} className="img-fluid" alt="partner" />
                </a>
              </div>
              <div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://coinmarketcap.com/dexscan/base/">
                  <img src={partner2} className="img-fluid" alt="partner" />
                </a>
              </div>
              <div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.uniswap.org/?chain=base">
                  <img src={partner3} className="img-fluid" alt="partner" />
                </a>
              </div>
              <div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://dexscreener.com/base/">
                  <img src={partner5} className="img-fluid" alt="partner" />
                </a>
              </div>
              <div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.geckoterminal.com/base">
                  <img src={partner6} className="img-fluid" alt="partner" />
                </a>
              </div>
              <div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.dexview.com/base/">
                  <img src={partner7} className="img-fluid" alt="partner" />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default Resume;
