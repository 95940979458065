import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../Assets/main/logo.png";
import { Link } from "react-router-dom";
import { AiOutlineHome, AiFillDollarCircle } from "react-icons/ai";
import { FaPeopleGroup } from "react-icons/fa6";
import { RiNftLine } from "react-icons/ri";
import { GoProjectRoadmap } from "react-icons/go";
import { BsFillPeopleFill } from "react-icons/bs";

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}>
      <Container>
        <Navbar.Brand href="/">
          <img src={logo} className="logo" alt="brand" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}>
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto" defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link href="#home" onClick={() => updateExpanded(false)}>
                <AiOutlineHome style={{ marginBottom: "2px" }} /> Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                target="_blank"
                rel="noreferrer"
                href="https://app.uniswap.org/?chain=base"
                onClick={() => updateExpanded(false)}>
                <AiFillDollarCircle style={{ marginBottom: "2px" }} /> Testing
                Base
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#nft" onClick={() => updateExpanded(false)}>
                <RiNftLine style={{ marginBottom: "2px" }} /> NFT
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#roadmap" onClick={() => updateExpanded(false)}>
                <GoProjectRoadmap style={{ marginBottom: "2px" }} /> Roadmap
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#partner" onClick={() => updateExpanded(false)}>
                <BsFillPeopleFill style={{ marginBottom: "2px" }} /> Partners
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#dao" onClick={() => updateExpanded(false)}>
                <FaPeopleGroup style={{ marginBottom: "2px" }} /> DAO
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
