import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Roadmapcontent from "./RoadmapContent";

function Roadmap() {
  return (
    <Container fluid className="roadmap-section" id="roadmap">
      <div className="roadmap-content">
        <div className="nft-text">
          <div className="nft-hr"></div>
          <span>Roadmap</span>
        </div>
        <Row className="roadmap">
          <Col md={6} className="roadmap-left">
            <h3 className="roadmap-title">PHASE 1</h3>
            <div className="roadmap-bg">
              <Roadmapcontent title="Launch Uni" content={[]} />
              <Roadmapcontent title="Website live" content={[]} />
              <Roadmapcontent title="Marketing" content={[]} />
              <Roadmapcontent title="Staking live" content={[]} />
            </div>
            <h3 className="roadmap-title">PHASE 2</h3>
            <div className="roadmap-bg">
              <Roadmapcontent title="Marketing Chinese" content={[]} />
              <Roadmapcontent title="Apply CMC" content={[]} />
              <Roadmapcontent title="List CMC" content={[]} />
              <Roadmapcontent title="Update DEX" content={[]} />
            </div>
          </Col>
          <Col md={6} className="roadmap-right">
            <h3 className="roadmap-title">PHASE 3</h3>
            <div className="roadmap-bg">
              <Roadmapcontent title="Audit" content={[]} />
              <Roadmapcontent title="KYC" content={[]} />
              <Roadmapcontent title="Website v2 live" content={[]} />
            </div>
            <h3 className="roadmap-title">PHASE 4</h3>
            <div className="roadmap-bg">
              <Roadmapcontent title="BaseDAO Testnet" content={[]} />
              <Roadmapcontent title="BaseDAO Mainnet" content={[]} />
              <Roadmapcontent title="Introduction NFT" content={[]} />
              <Roadmapcontent title="NFT collection" content={[]} />
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default Roadmap;
