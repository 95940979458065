import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Nft() {
  return (
    <Container fluid className='nft-section' id='nft'>
      <div className='nft-content'>
        <div className='nft-text'>
          <div className='nft-hr'></div>
          <span>NFT</span>
        </div>
        <span className='nft-text2'>COMMING SOON!</span>
      </div>
    </Container>
  );
}
export default Nft;
